import React from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import { HBTurq } from '../../../../config/theme';
import { Link } from 'react-router-dom'

const Container = styled.div`
  /* border: 1px red dashed; */
  max-width: 500px;
  height: 450px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  /* justify-content: center; */
  ${media.lessThan("medium")`
    width: 350px;
    `}
`
const ProjectTitle = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
    margin-top: 40px;
    margin-bottom: 60px;
    align-self: center;
`;

const Button = styled.button`
    /* border: 1px blue dashed; */
    outline: none;
    width: 240px;
    height: 55px;
    color: ${HBTurq};
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 30px;
    box-shadow: 0px 0px 5px ${HBTurq};
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 20px;
    align-self: center;
    margin-top: 6px;
    margin-bottom: 6px;
    transition-duration: 0.5s;
    &:hover{
        transform: scale(1.05);
        background-color: #6EFAFF;
        color: #000;
        opacity: 0.9;
        }
    &:active{
        opacity: 0.9;
        transform: scale(0.95);
        }
        transition-duration: 0.5s;
`;

export default function FinishedSurvey() {
    return (
        <div>
            <Container>
              <ProjectTitle>Thank you for your feedback! </ProjectTitle>
            </Container>
        </div>
    )
}
