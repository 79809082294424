import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter
} from "react-router-dom";
import './config';
import { Reset } from "styled-reset";
import { ThemeProvider } from 'styled-components';
import * as theme from "./config/theme"
import BootNav from "./ui/component/App/NavBar";
import Footer from "./ui/component/App/Footer";
import HearBeyondLayout from "./ui/component/HearBeyond/HearBeyondLayout";
import BlogLayout from "./ui/component/Blog/BlogLayout";
import UserSurveyContainer from "./ui/component/Survey/UserFeedback/UserSurveyContainer";
import ServicesLayout from "./ui/component/Services/ServicesLayout";
import HomeLayout from "./ui/component/App/HomeLayout";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

function App() {
  return (
    <div>
      <Reset />
      <ThemeProvider theme={theme}>
          <Router>

            <BootNav/>    
            <Switch>

                <Route path="/hearbeyond">
                  <HearBeyondLayout />
                </Route>

                <Route path="/">
                  <HomeLayout />
                </Route>
              </Switch>
            <Footer/>
      
          </Router>
      </ThemeProvider>
    </div>

  );
}

export default App;

{/* <ScrollToTop> */}
// </ScrollToTop>