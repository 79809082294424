import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import OurExpertise from './../HearBeyond/OurExpertise';
import ImageBg from "./../../../res/images/bg_code.jpg";
import ImageBgTablet from "./../../../res/images/bg_code_tablet.jpg";
import ImageBgPhone from "./../../../res/images/bg_code_phone.jpg";
import ImgIcon from "./../../../res/images/ic_arrow_right.svg";
import ImageDownloadApp from "./../../../res/images/DownloadApp.svg"
import TagManager from 'react-gtm-module'
import { HBTurq } from '../../../config/theme';
import ExploreWork from '../HearBeyond/ExploreWork';
import HowWeHelp from '../HearBeyond/HowWeHelp';
import ClientTestimonials from '../HearBeyond/ClientTestimonials';
import ContactUs from '../HearBeyond/ContactUs';
import { HashLink } from 'react-router-hash-link';

const GridContainer = styled.div`
  /* border: 1px red dashed; */
  display: grid;
  grid-template: auto auto auto auto auto auto / 100%;
  overflow: hidden;
`;

const Grid = styled.div`
  /* border: 1px blue dashed; */
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  max-width: 1440px;
  height: 90vh;
  justify-self: center;
  display: flex;
  padding: 35px;
  ${media.lessThan("medium")`
    height: 100vh;
    /* padding-bottom: 0px; */
    justify-content: center;
  `}
  ${media.between("medium", "large")`
  height: 90vh;
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 160px;
  ${media.lessThan("medium")`
    justify-content: center;
    height: 300px;
    align-self: center;
    margin-left: 0px;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const KeywordText = styled.h2`
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  justify-self: center;
  line-height: 30px;
  font-size: 20px;
  color: #E3E3E3;
  text-shadow: 1px 1px 4px #000;
  margin-bottom: 20px;
  ${media.lessThan("medium")`
    font-size: 15px;
    margin-bottom: 10px;
`}
  ${media.between("medium", "large")`
`}
`;

const TitleText = styled.h1`
  max-width: 750px;
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  justify-self: center;
  font-size: 38px;
  color: #FFF;
  text-shadow: 1px 1px 4px #000;
  line-height: 50px;
  ${media.lessThan("medium")`
  font-size: 30px;
`}
  ${media.between("medium", "large")`
`}
`;



const ButtonContainer = styled.div`
  /* border: 1px red dashed; */
  /* height: 58px; */
  width: auto;
  display: flex;  
  flex-direction: row;
  margin-top: 20px;
  ${media.lessThan("medium")`
    /* flex-direction: column; */
`}
  ${media.between("medium", "large")`
`}
`;

const BtnLearnHow = styled.button`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  border: none;
    box-shadow: 0px 0px 10px #15B1B2;
  width: 200px;
  height: 55px;
  border-radius: 9px;
  padding: 20px;
  background: linear-gradient(#6efaff 0%, #15b1b2 100%);
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  color: #000;
  text-align: center;
  justify-content: center;
  ${media.lessThan("medium")`
  height: 50px;
`}

  ${media.between("medium", "large")`
`}
`;

const ButtonText = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #000;
  align-self: center;
`;

const ArrowIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 10px;
  align-self: center;
`;

const BackgroundImage = styled.div`

  grid-area: 1 / 1 / 2 / 2;
  background-image: url(${ImageBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  /* opacity: 40%; */
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
  }
  ${media.lessThan("medium")`
  background-image: url(${ImageBgPhone});
  /* background-image: url(${ImageBgTablet}); */
`}
  ${media.between("medium", "large")`
  `}
`;

export default class DvorakLayout extends Component {

  componentDidMount(){
    TagManager.initialize(tagPageVisit)
  }

  handleiOSLink = () => {
    TagManager.initialize(tagiOSLink)
  }
  handleAndroidLink = () => {
    TagManager.initialize(tagAndroidLink)
  }

  render() {
  
    return (
      <GridContainer>
        <Grid>
          <BackgroundImage/>
          <TitleContainer>
            <KeywordText>MOBILE & WEB APP DEVELOPMENT</KeywordText>
            <TitleText>We join forces with the ambitious to build audacious digital technologies.</TitleText>
              <ButtonContainer> 
                <div style={{alignSelf: 'center'}} onClick={this.handleiOSLink}> 
                <HashLink to="/#objective"  style={{ textDecoration: 'none' }}>
                      <BtnLearnHow src={ImageDownloadApp}>
                        <ButtonText>Explore how</ButtonText>
                        
                        <ArrowIcon src={ImgIcon} />
                      </BtnLearnHow>
                  </HashLink>

                  </div>  
                </ButtonContainer>
          </TitleContainer>
        </Grid>
        <OurExpertise />
        <ExploreWork />
        <HowWeHelp />
        <ContactUs />
        </GridContainer>
        )
      }
    }

    // <ClientTestimonials />
    
    const tagPageVisit = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_page_visit'
      }
    }

    const tagiOSLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_link_ios'
      }
    }

    const tagAndroidLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
        tag: 'hearbeyond_link_android'
      }
    }


    
    

