import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import MarqueeSlider from './MarqueeSlider';

import Aos from "aos";
import "aos/dist/aos.css";


const Grid = styled.div`
  /* border: 1px yellow dashed; */
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  /* padding-top: 40px; */
  padding-bottom: 80px;
  background-color: black;
  padding-top: 100px;
  height: 100%;
  ${media.lessThan("medium")`
  padding-top: 100px;
  `}

  ${media.between("medium", "large")`
  margin-top: 100px;
  `}

  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 600px;
  align-self: center;
  ${media.lessThan("medium")`
  width: 90%
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const Title = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 2.2em;
  font-weight: 400;
  line-height: 40px;
  z-index: 1;
  color: white;
  text-shadow: 1px 1px 4px #000;
  justify-self: center;
  text-align: center;
  align-self: center;
  margin-bottom: 40px;
  ${media.lessThan("medium")`
  font-size: 1.5em;
  `}

  ${media.between("medium", "large")`
  align-self: center;
  `}
`;


  const AboutText = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #FFF;
    text-shadow: 1px 1px 4px #000;
    align-self: center;
        text-align: center;
        line-height: 40px;
    ${media.lessThan("medium")`
        font-size: 15px;
        width: 90%;
  `}

    ${media.between("medium", "large")`
      font-size: 1.2em;
      text-align: center;
  `}
  `;


const TickerContainer = styled.div`
    /* border: 1px green dashed; */
    height: auto;
    width: auto;
    z-index: 1;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
    /* margin-bottom: 40px; */
    ${media.lessThan("medium")`
    `}
    ${media.between("medium", "large")`
    `}
`;

export default class BannerBlurb extends Component {

  state = {
        move: false
    }

    componentDidMount(){
        Aos.init()

        setTimeout(function() {
            var resizeEvent = new Event("resize");
            window.dispatchEvent(resizeEvent);
        }.bind(this), 1000)

      }

  render() {
  return (
        <Grid>
          <TitleContainer>
            <Title>Welcome to Hear Beyond!</Title>
            <AboutText>
              Hear Beyond invites you to strengthen your auditory brain to enhance your ability to 
              separate what you are trying to hear from the noisy environment around you. This leads to 
              improved communication helping your social interactions be more enjoyable and less work. 
            </AboutText>
          </TitleContainer>
          <TickerContainer>
            <MarqueeSlider />
          </TickerContainer>
          </Grid>
          )
        }
      }
      