import { thisExpression } from '@babel/types'
import React, { Component } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import UserSurveyParent from './UserSurveyStepParent'
import FinishedSurvey from './UserSurveyFinished'
import SurveyIntro from './UserSurveyIntro'

const Container = styled.div`
    height: 90vh;
    ${media.lessThan("medium")`
    height: 100vh;
    `}
`;

export default class UserSurveyContainer extends Component {

    state = {
        started: false,
        finished : false
    }

    startSurvey = () => {
        this.setState({started: true})
    }

    finishSurvey = () => {
        this.setState({finished: true})
    }

    render() {
        return (
            <Container>

                {this.state.started ?                      
                    this.state.finished ? 
                        <FinishedSurvey/> 
                    : 
                        <UserSurveyParent finish={this.finishSurvey}/> 
                :  
                    <SurveyIntro start={this.startSurvey}/>}

            </Container>
        )
    }
}


