import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
// import TrainYourEars from './TrainYourEars';
import ImgIcon from "./../../../res/images/ic_idea.svg";
import ImgIcon2 from "./../../../res/images/ic_draft.svg";
import ImgIcon3 from "./../../../res/images/ic_code.svg";
import ImgIcon4 from "./../../../res/images/ic_market.svg";
import ImgBtnIcon from "./../../../res/images/ic_arrow_right.svg";
import ImgThinking from "./../../../res/images/thinking.jpg";
import TagManager from 'react-gtm-module'
import { HBTurq } from '../../../config/theme';
import { HashLink } from 'react-router-hash-link';   


const Grid = styled.div`
  /* border: 1px red dashed; */
  grid-area: 2 / 1 / 3 / 2;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* padding-bottom: 120px; */
  background-color: white;
  ${media.lessThan("medium")`
  padding-left: 0px;
;  `}
  ${media.between("medium", "large")`
    padding-left: 0px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const Container = styled.div`
  /* border: 1px red dashed; */
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 35px;
  ${media.lessThan("medium")`
;  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;


const TitleContainer = styled.div`
  /* border: 1px blue dashed; */
  max-width: 1440px;
  display: flex;
  height: auto;
  flex-direction: row;
  padding-top: 80px;
  ${media.lessThan("medium")`
    justify-content: center;
    padding-top: 60px;
    flex-direction: column-reverse;
  `}
  ${media.between("medium", "large")`
    padding-top: 60px;
    flex-direction: column-reverse;
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleTextContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  height: auto;
  flex-direction: column;
  ${media.lessThan("medium")`
    justify-content: center;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const KeywordText = styled.h2`
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  justify-self: center;
  font-size: 20px;
  color: #15b1b2;
  margin-bottom: 20px;
  ${media.lessThan("medium")`
    font-size: 15px;
`}
  ${media.between("medium", "large")`
`}
`;

const TitleText = styled.h1`
  max-width: 900px;
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: black;
  justify-self: center;
  font-size: 30px;
  line-height: 50px;
  ${media.lessThan("medium")`
  font-size: 20px;
`}
  ${media.between("medium", "large")`
`}
`;

const TitleImg = styled.img`
  width: 400px;
  height: auto;
  ${media.lessThan("medium")`
  width: 300px;
  padding-bottom: 50px;
  align-self: center;
`}
  ${media.between("medium", "large")`
  width: 300px;
  padding-bottom: 50px;
  align-self: center;
`}
`;

const CardContainer = styled.div`
    /* border: 2px green dashed; */
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
    margin-top: 80px;
    ${media.lessThan("medium")`
    flex-direction: column;
    `}
  ${media.between("medium", "large")`
    flex-direction: column;
    `}
`;

const CardRowContainer = styled.div`
    /* border: 2px yellow dashed; */
    display: flex;
    flex-direction: row;
    width: 50%;
    ${media.lessThan("medium")`
        flex-direction: column;
        width: 100%;
    `}
  ${media.between("medium", "large")`
        flex-direction: row;
        width: 100%;
    `}
`;

const Card = styled.div`
    /* border: 2px red dashed; */
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 40px;
    ${media.lessThan("medium")`
    width: 100%;
    margin: 0px;
    margin-bottom: 20px;
    `}
  ${media.between("medium", "large")`
    width: 50%;
    `}
`;

const Icon = styled.img`
    /* border: 1px red dashed; */
    /* width: 80px; */
    height: 80px;
    align-self: flex-start;
`;

const CardTitle = styled.h1`
    font-family: Montserrat;
    font-weight: 600;
    font-size: 22px;
    line-height: 68px;
    text-align: left;
    color: #000;
    margin-top: 20px;
`;

const CardText = styled.p`
    font-family: Montserrat;
    font-weight: normal;
    font-size: 17px;
    line-height: 40px;
    text-align: left;
    color: #000;
    ${media.lessThan("medium")`
    font-size: 16px;
`}
`;

const BtnLearnHow = styled.div`
  display: flex;
  flex-direction: row;
  /* border: 1px green dashed; */
  border: none;
  width: 160px;
  height: 55px;
  border-radius: 9px;
  justify-content: start;
  ${media.lessThan("medium")`
  margin-left: 0px;
  height: 50px;
`}

  ${media.between("medium", "large")`
  margin-left: 0px;
`}
`;

const ButtonText = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #000;
  align-self: center;
`;

const ArrowIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 10px;
  align-self: center;
`;

const PrivacyTag = styled.a`
  text-decoration: none;
  ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
`}
`;

export default class OurExpertise extends Component {

  componentDidMount(){
    // TagManager.initialize(tagPageVisit)
  }

  handleiOSLink = () => {
    TagManager.initialize(tagiOSLink)
  }
  handleAndroidLink = () => {
    TagManager.initialize(tagAndroidLink)
  }

  render() {
  
    return (
        <Grid>
            <Container>
                <TitleContainer>
                    <TitleTextContainer>
                      <KeywordText id='objective'>OUR MAIN OBJECTIVE</KeywordText>
                      <TitleText>
                          To transform viable ideas into digital solutions that solve everyday problems. We code scalable mobile and web
                          applications with the expertise needed to ensure your product arrives to the marketplace.
                      </TitleText>
                    </TitleTextContainer>
                    <TitleImg src={ImgThinking} />
                </TitleContainer>
                <CardContainer>
                    <CardRowContainer>
                        <Card>
                            <Icon src={ImgIcon} /> 
                            <CardTitle>Explore the idea</CardTitle>
                            <CardText>
                              Let's connect to hear about your idea and discover what it would take to turn your idea into a real app in the hands of users. 
                            </CardText>
                            <HashLink to="/#contact"  style={{ textDecoration: 'none' }}>
                            <BtnLearnHow>
                              <ButtonText>Get started</ButtonText>
                                <ArrowIcon src={ImgBtnIcon} />
                            </BtnLearnHow>
                            </HashLink>
                        </Card>
                        <Card>
                            <Icon src={ImgIcon2} /> 
                            <CardTitle>Draft the design </CardTitle>
                            <CardText>
                              We'll turn the idea into a design that works and makes sense. We'll create a rough draft that you can click-through and sign-off on.
                            </CardText>
                        </Card>
                    </CardRowContainer>
                    <CardRowContainer>
                        <Card>
                            <Icon src={ImgIcon3} /> 
                            <CardTitle>Code the solution</CardTitle>
                            <CardText>
                                Our team of developers will transform the design into reliable code running on your device and make sure it's what you want.
                            </CardText>
                        </Card>
                        <Card>
                            <Icon src={ImgIcon4} /> 
                            <CardTitle>Deploy to market</CardTitle>
                            <CardText>
                                We'll deploy your app safely to the Apple Store, Google Play Store or onto the World Wide Web. Wherever your apps users may be. 
                            </CardText>
                        </Card>
                    </CardRowContainer>
                </CardContainer>
          </Container>
        </Grid>
        )
      }
    }
    
    const tagPageVisit = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_page_visit'
      }
    }

    const tagiOSLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_link_ios'
      }
    }

    const tagAndroidLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
        tag: 'hearbeyond_link_android'
      }
    }


    
    

