import React, { Component, useState } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import { HBTurq } from './../../../../config/theme';

const ButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.button`
    outline: none;
    width: 100%;
    height: 50px;
    display: flex;

    /* margin-right: 10px; */
    flex-direction: row;
    margin-right: 30px;
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 10px;
    box-shadow: 0px 0px 10px ${HBTurq};
    ${media.lessThan("medium")`
    margin-top: 15px;
    margin-right: 10px;
    `}
    ${media.between("medium", "large")`
      margin-top: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const PressedButton = styled(Button)`
    /* border: 3px solid ${props => 'blue' || 'blue' }; */
    border: 3px solid blue;
`;

const ButtonText = styled.p`
  /* border: 1px red dashed; */
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 17px;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    align-self: center;
    padding-right: 10px;
`;

const Icon = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 5px;
    align-self: center;
    transition-duration: 0.5s;
    ${media.lessThan("medium")`
    `}
    ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`;

export default function SurveyYesNoButton(props) {

    const [isPressed, setIsPressed] = useState(false)

    const handleAnswer = () => {
        if(isPressed === false){
            setIsPressed(true)
            if(props.answer === 'Yes'){
                props.setConditional(true)
            } else {
                props.setConditional(false) 
            }
        } else {
            setIsPressed(false)
            props.setConditional(null)
        }       
}

    return (
        <div style={{ marginRight: 20 }}>
            { props.conditional === null ?  
                <Button onClick={handleAnswer}>
                    <Icon src={props.image}></Icon>
                        <ButtonText>
                            {props.answer}
                        </ButtonText>
                </Button>
            :
              isPressed ? 
                <PressedButton onClick={handleAnswer}>
                    <Icon src={props.image}></Icon>
                        <ButtonText>
                        {props.answer}
                        </ButtonText>
                </PressedButton>
                    :
                <Button onClick={handleAnswer}>
                    <Icon src={props.image}></Icon>
                        <ButtonText>
                            {props.answer}
                        </ButtonText>
                </Button>                
            }
        </div>
    )
}



