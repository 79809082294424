import React, { Component } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import TextArea from '../Common/SurveyTextArea'
import SurveyInput from '../Common/SurveyInput'
import { HBTurq } from '../../../../config/theme';
import {ProjectTitle} from './../Common/SurveyStyles'

export const Container = styled.div`
  /* border: 1px dashed yellow; */
  max-width: 500px;
  height: 450px;
  margin-top: 80px;
  ${media.lessThan("medium")`
    width: 350px;
    `}
`

const FinishContainer = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
`;

const Button = styled.button`
    /* border: 1px blue dashed; */
    outline: none;
    width: 240px;
    height: 55px;
    color: ${HBTurq};
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 30px;
    box-shadow: 0px 0px 5px ${HBTurq};
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 16px;
    align-self: center;
    margin-top: 16px;
    margin-bottom: 6px;
    transition-duration: 0.5s;
    &:hover{
        transform: scale(1.05);
        background-color: #6EFAFF;
        color: #000;
        opacity: 0.9;
        }
    &:active{
        opacity: 0.9;
        transform: scale(0.95);
        }
        transition-duration: 0.5s;
`;

export default class Step5 extends Component  {

    state = {
        finished: false
    }

    finishSurvey = () => {
        this.props.ship()
        this.setState({finished: true})
    }

 render() {
   return (
       <div>
           <Container>
               {this.state.finished? 
                null
               : 
               <FinishContainer>
                <ProjectTitle>Press Finish to send your survey</ProjectTitle>
                <Button onClick={this.finishSurvey} >   FINISH </Button>
               </FinishContainer>
               }
           </Container>
       </div>
   )
 }
}
