import React, {Component} from 'react'
import TextArea from '../Common/SurveyTextArea'
import SurveyInput from '../Common/SurveyInput'
import { HBTurq } from '../../../../config/theme';
import {Container, ProjectTitle} from './../Common/SurveyStyles'

export default class Step4 extends Component  {

  q = "Any suggestions for the developers?"
  e = "Other"

  state = {
    finalAnswers: [],
    userInput: ""
  }

  componentWillReceiveProps(nextProps) {
   if (nextProps.shouldSend !== this.props.shouldSend && nextProps.shouldSend === 5) {
     this.sendAnswer()
   }
}

  addAnswer = (answer) => {
   let answersArray = this.state.finalAnswers
   answersArray.push(answer)
   this.setState({ finalAnswers : answersArray})
 }

 removeAnswer = (answer) => {
   let answersArray = this.state.finalAnswers
   const index = answersArray.indexOf(answer);
   if (index > -1) {
     answersArray.splice(index, 1);
     this.setState({ finalAnswers : answersArray})
   }
 }

 passInput = (input) => {
   // pass this into input to get it's data
   this.setState({ userInput : input})
 }

  sendAnswer = () => {
   // Wrap up question, answers and custom input for pass to parent
   let answersArray = this.state.finalAnswers
   answersArray.unshift(this.q)
   answersArray.push(this.state.userInput)
   this.props.answer(answersArray)
 }

 render() {
   return (
       <div>
           <Container>
               <ProjectTitle>{this.q}</ProjectTitle>
               <TextArea input={this.state.userInput} pass={this.passInput} pass={this.passInput}  >Other</TextArea>            
           </Container>
       </div>
   )
 }
}
