import React from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import { HBTurq } from '../../../../config/theme';

const Input = styled.input`
    width: 90%;
    height: 55px;
    outline: none!;
    border: 2px solid ${HBTurq};
    border-radius: 10px;
    box-shadow: 0px 0px 10px ${HBTurq};
    outline: none;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.1em;
    font-weight: 400;
    background-color: #000000;
    box-sizing: border-box;
    padding: 10px;
    :focus {
        border: 3px solid blue;
      }
    ${media.lessThan("medium")`
      align-self: center;
    `}
    ${media.between("medium", "large")`
      align-self: center;
    `}
    ${media.greaterThan("large")`
    /* margin-left: 200px; */
    `}
`;

const PressedInput = styled(Input)`
    border: 3px solid blue;
`;

export default function SurveyInput(props) {

    return (
    <div>
        { props.input.length > 0 ? 
            <PressedInput 
            autoFocus
            type="text"
            placeholder="Other"
            value={props.input}
            onChange={(e) => {
                props.pass(e.target.value)
            }}></PressedInput>
            :
            <Input 
            type="text"
            placeholder="Other"
            value={props.input}
            onChange={(e) => {
                props.pass(e.target.value)
            }}></Input>
        }
    </div>
    )
}
