import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import BannerBlurb from './BannerBlurb';
import ImageBg from "./../../../res/images/bg_CodeMountain_top2.jpg";
import ImageBgPhone from "./../../../res/images/bg_CodeMountain_topPhone.jpg";
import ImageLogo from "./../../../res/images/Logo_HearBeyond.png"
import ImageLogoPhone from "./../../../res/images/Logo_HearBeyond_Tech.png"
import ImagePhone from "./../../../res/images/iPhone_Library_Games.png";
import ImageDownloadApp from "./../../../res/images/DownloadApp.svg"
import ContactUs from './ContactUs';
import SubscribeEmail from './SubscribeEmail';
import TagManager from 'react-gtm-module'

const GridContainer = styled.div`
  /* border: 1px red dashed; */
  display: grid;
  grid-template: auto auto auto / 100%;
  overflow: hidden;
  /* height: auto; */
`;

const Grid = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  width: 80%;
  max-width: 1440px;
  justify-self: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 180px;
  padding-bottom: 170px;
  justify-content: space-evenly;
  align-content: center;
  ${media.lessThan("medium")`
    flex-direction: column;
    margin-top: 0px;
    padding-bottom: 100px;
  `}
  ${media.between("medium", "large")`
    flex-direction: row;
    margin-top: 60px;
    padding-top: 160px;
    padding-bottom: 40px;
    justify-content: space-evenly;
  `}
  ${media.greaterThan("large")`
    margin-left: -100px;
  `}
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  transition-duration: 0.5s;
    &:hover{
        transform: scale(1.1);
        }
  ${media.lessThan("medium")`
    margin-top: 40px;
  `}

  ${media.between("medium", "large")`
  margin-top: 40px;

  `}
`; 

const PhoneGamesMenu = styled.div`
  /* border: 1px green dashed; */
  background-image: url(${ImagePhone});
  background-size: cover;
  background-repeat: no-repeat;
  outline: none!;
  z-index: 1;
  height: 450px;
    width: 220px;
    ${media.lessThan("medium")`
        height: 360px;
        width: 180px;
    `}

  ${media.between("medium", "large")`
      height: 360px;
        width: 180px;
  `}

  ${media.greaterThan("large")`
  `}
`;

const HearBeyondImage = styled.div`
    background-image: url(${ImageLogo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    outline: none!;
    z-index: 1;
    height: 135px;
    width: 600px;
    align-self: center;
    transition-duration: 0.5s;
    &:hover{
        transform: scale(1.1);
        }
  ${media.lessThan("medium")`
    background-image: url(${ImageLogoPhone});
    height: 130px;
    width: 360px;
    margin-bottom: 10px;
    margin-top: 0px;
  `}
  ${media.between("medium", "large")`
    background-image: url(${ImageLogoPhone});
    height: 175px;
    width: 360px;
    margin-bottom: 10px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
  /* border: 1px blue dashed; */
  grid-template: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
  /* margin-right: 60px; */
  /* width: 90% */

  `}
  ${media.greaterThan("large")`
    /* margin-right: 100px; */
  `}
`;

const AboutText = styled.p`
  width: 500px;
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  justify-self: center;
  font-size: 18px;
  color: #FFF;
  text-shadow: 1px 1px 4px #000;
  line-height: 34px;
  /* align-self: center; */
  margin-left: 195px;
  margin-bottom: 0px;
  ${media.lessThan("medium")`
    font-size: 15px;
    width: 250px;
    margin-left: 0px;
    align-self: center;
    text-align: center;
    margin-top: 10px;
`}

  ${media.between("medium", "large")`
    font-size: 1.2em;
    /* margin-left: 140px;    */
    width: 360px; 
    margin-left: 0px;
    align-self: center;
    text-align: center;
    margin-top: 10px;
`}
`;


const PrivacyTag = styled.a`
  text-decoration: none;
  align-self: center;
  ${media.lessThan("medium")`
  `}
  
  ${media.between("medium", "large")`
  align-self: center;
`}
`;

const PrivacyTag2 = styled.a`
  text-decoration: none;
  align-self: center;
  ${media.lessThan("medium")`
  `}
  
  ${media.between("medium", "large")`
  align-self: center;
`}
`;

const ButtonContainer = styled.div`
  /* border: 1px red dashed; */
  height: 58px;
  width: auto;
  display: flex;  
  flex-direction: row;
  align-content: center;
  justify-content: center;
  margin-left: -24px;
  margin-top: 10px;
  ${media.lessThan("medium")`
    margin-left: 0px;
    margin-top: 10px;
`}
  ${media.between("medium", "large")`
    margin-left: 0px;
    margin-top: 10px;
`}
`;

const BtnDownloadApple = styled.img`
  /* border: 1px green dashed; */
  width: auto;
  height: 40px;
  border-radius: 8px;
  margin-right: 5px;
  transition-duration: 0.5s;
    &:hover{
        opacity: 0.9;
        transform: scale(1.05);
        }
  ${media.lessThan("medium")`
  margin-left: 0px;
`}

  ${media.between("medium", "large")`
  margin-left: 0px;
`}
`;


const BtnDownloadGoogle = styled.img`
  /* border: 1px yellow dashed; */
  width: auto;
  height: 58px;
  border-radius: 8px;
  transition-duration: 0.5s;
    &:hover{
        opacity: 0.9;
        transform: scale(1.05);
        }
  ${media.lessThan("medium")`
  margin-left: 0px;
  /* width: 159px; */
  /* height: 52.5px; */
`}

  ${media.between("medium", "large")`
  /* width: 159px; */
  /* height: 52.5px; */
  margin-left: 0px;
`}
`;


const BackgroundImage = styled.div`

  grid-area: 1 / 1 / 2 / 2;
  background-image: url(${ImageBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  /* opacity: 40%; */
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
  }
  ${media.lessThan("medium")`
    background-image: url(${ImageBgPhone});
`}
`;

export default class DvorakLayout extends Component {

  componentDidMount(){
    // TagManager.initialize(tagPageVisit)
  }

  handleiOSLink = () => {
    TagManager.initialize(tagiOSLink)
  }
  handleAndroidLink = () => {
    TagManager.initialize(tagAndroidLink)
  }

  render() {
  
    return (
      <GridContainer>
        <Grid>
          <BackgroundImage/>
          <TitleContainer>
            <HearBeyondImage />
            <AboutText>Strengthen your Auditory Brain by exploring the nature of sound, listening and hearing</AboutText>
              <ButtonContainer> 
                <div style={{alignSelf: 'center'}} onClick={this.handleiOSLink}> 
                  <PrivacyTag
                      target="_blank" 
                      href="https://apps.apple.com/ca/app/hear-beyond/id1529830800">
                      <BtnDownloadApple src={ImageDownloadApp}/> 
                    </PrivacyTag>
                  </div>  
                  <div style={{alignSelf: 'center'}}  onClick={this.handleAndroidLink}> 
                    <PrivacyTag2
                      target="_blank" 
                      href="https://play.google.com/store/apps/details?id=com.hearbeyondandroid">
                      <BtnDownloadGoogle src="https://play.google.com/intl/en_us/badges/images/generic/en-play-badge.png" /> 
                    </PrivacyTag2>
                  </div>  
                </ButtonContainer>
              {/* <SubscribeEmail/> */}
              {/* <Subscribe/> */}
            {/* <LegalButton>
              <Link to="/hearbeyond/terms"><Policy>Terms Of Use</Policy></Link>
              <Link to="/hearbeyond/privacy"><Policy>Privacy Policy</Policy></Link>
            </LegalButton> */}
          </TitleContainer>
          <LogoContainer>
            <PhoneGamesMenu />
          </LogoContainer>
        </Grid>
        <BannerBlurb/>
        {/* <ParallaxDivide/> */}
        {/* <CreatedBy /> */}
        {/* <ExploreSounds /> */}\
        {/* <ContactUs/> */}
        </GridContainer>
        )
      }
    }
    
    const tagPageVisit = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_page_visit'
      }
    }

    const tagiOSLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_link_ios'
      }
    }

    const tagAndroidLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
        tag: 'hearbeyond_link_android'
      }
    }


    
    

