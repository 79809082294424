import React from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import { HBTurq } from '../../../../config/theme';

const TextArea = styled.textarea`
    width: 90%;
    height: 140px;
    outline: none!;
    border: 2px solid ${HBTurq};
    outline: none;
    border-radius: 8px;
    box-shadow: 0px 0px 10px ${HBTurq};
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.1em;
    font-weight: 400;
    background-color: #000000;
    box-sizing: border-box;
    padding: 10px;
    :focus {
        border: 3px solid blue;
      }
    ${media.lessThan("medium")`
      align-self: center;
    `}
    ${media.between("medium", "large")`
      align-self: center;
    `}
    ${media.greaterThan("large")`
    `}
`;

export default function SurveyTextArea(props) {

    return (
    <div>
        <TextArea 
        type="text"
        placeholder="Other"
        value={props.input}
        onChange={(e) => {
            props.pass(e.target.value)
        }}></TextArea>
    </div>
    )
}