import React, { Component } from 'react'
import { MultiStepForm, Step } from 'react-multi-form';
import styled from 'styled-components'
import media from "styled-media-query";
import { HBTurq } from '../../../../config/theme';
import TagManager from 'react-gtm-module'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

const Container = styled.div`
  max-width: 500px;
  margin: 40px auto;
  padding: 50px 50px;
  background-color: black;
  @media(max-width: 590px) {
    width: 90%;
    padding: 0px;
    align-self: center;
    border: none;
    border-radius: none;
    box-shadow: none;
  }
`

const Button = styled.button`
    border: 1px yellow dashed;
    outline: none;
    width: 100px;
    height: 45px;
    color: ${HBTurq};
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 30px;
    box-shadow: 0px 0px 5px ${HBTurq};
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 16px;
    align-self: center;
    transition-duration: 0.5s;
    &:hover{
        background-color: #6EFAFF;
        color: #000;
        opacity: 0.9;
        transform: scale(1.05);
        }
    ${media.lessThan("medium")`
    margin-top: 15px;
    `}
    ${media.between("medium", "large")`
      margin-top: 15px;
    `}
    ${media.greaterThan("large")`
      margin-left: 10px;
    `}
`;

const HiddenButton = styled(Button)`
    visibility: hidden;
`

const DoneButton = styled(Button)`

`

export default class UserSurvey extends Component {

    state = {
        active: 1,
        step1: [],
        step2: [],
        step3: [],
        step4: []
    }

    nextStep = () => {
        let active = this.state.active
        this.setState({active: active + 1})
    }

    previousStep = () => {
        let active = this.state.active
        this.setState({active: active - 1})
    }

    step1Answer = (answer) => {
        let answerArray = [answer]
        this.setState({step1: answerArray})
    }

    step2Answer = (answer) => {
        let answerArray = [answer]
        this.setState({step2: answerArray})
    }

    step3Answer = (answer) => {
        let answerArray = [answer]
        this.setState({step3: answerArray})
    }

    step4Answer = (answer) => {
        let answerArray = [answer]
        this.setState({step4: answerArray})
    }

    shipAnswer = async () => {
        try {
            let result = await fetch(
              "https://10ww8j15w1.execute-api.us-east-2.amazonaws.com/prod/sendusersurvey",
              {
                method: "post",
                mode: "no-cors",
                headers: {
                  Accept: "application/json",
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  step1: this.state.step1,
                  step2: this.state.step2,
                  step3: this.state.step3,
                  step4: this.state.step4,
                }),
              }
            );
            TagManager.initialize(tagMessageSent)
          } catch (e) {
            console.log("BIG ERROR", e);
          }
        this.props.finish()
    }

    render() {
        return (
            <Container>
                <MultiStepForm activeStep={this.state.active}>
                    <Step label='step1'>
                        <Step1 answer={this.step1Answer} shouldSend={this.state.active}/>
                    </Step>
                    <Step label='step2'>
                        <Step2 answer={this.step2Answer} shouldSend={this.state.active}/>
                    </Step>
                    <Step label='step3'>
                        <Step3 answer={this.step3Answer} shouldSend={this.state.active}/>
                    </Step>
                    <Step label='step4'>
                        <Step4 answer={this.step4Answer} shouldSend={this.state.active}/>
                    </Step>
                    <Step label='finito'>
                        <Step5 ship={this.shipAnswer}/>
                    </Step>
                </MultiStepForm>

      {this.state.active === 5 ?
        null
        : 
        <Button onClick={this.nextStep} style={{ float: 'right' }}> Next </Button> 
      }
            </Container>
        )
    }
}

const tagMessageSent = {
    gtmId: global.config.gtmId,
    dataLayer: {
        tag: 'hearbeyond_survey_sent'
    }
  }
