import React, { Component } from 'react'
import styled from 'styled-components'
import media from "styled-media-query"
import { HBTurq } from "../../../config/theme";
import ImgCompMeme from "./../../../res/images/ComputerMeme.jpg";
import Img2 from "./../../../res/images/SpheresForest2.jpg";
import Img3 from "./../../../res/images/CodeMountain_portrait.jpg";
import Img4 from "./../../../res/images/GoButton.jpg";
import Img5 from "./../../../res/images/SoundinNoise.jpg";
import Img6 from "./../../../res/images/Localizer360.jpg";
import Img7 from "./../../../res/images/AuditoryProcessing.jpg";
import Img8 from "./../../../res/images/AppStoreLaunch.jpg";
import Img9 from "./../../../res/images/AuditoryBrain.jpg";
import Img10 from "./../../../res/images/BetaRelease.jpg";

const Container = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: radial-gradient(#060b1d 0%, #060b1d 50%, #000 100%);
    `;

const PageTitle = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 36px;
    color: #FFFFFF;
    align-self: center;
    margin-top: 50px;
    ${media.lessThan("medium")`
    font-size: 30px;
    `}
`;

const Card = styled.div`
    display: flex;
    flex-direction: row;
    width: 1000px;
    height: auto;
    padding: 80px 80px;
    border-radius: 20px;
    background-color: #EFEFEF;
    align-self: center;
    margin-top: 30px;
    transition-duration: 0.5s;
    &:hover{
        border-radius: 30px;
        box-shadow: 0px 0px 20px ${HBTurq};
        transform: scale(1.05);
    }
    ${media.lessThan("medium")`
    flex-direction: column;
        align-content: center;
        width: 90%;
        padding: 30px 0px;
  `}
  ${media.between("medium", "large")`
        flex-direction: column;
        width: 70%; 
  `}
`;

const Card2 = styled(Card)`
    box-shadow: none;
`;

const CardTextContainer = styled.div`
    /* border: 1px green dashed; */
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    ${media.lessThan("medium")`
        width: 80%;
        margin-left: 0px;
        align-self: center;
  `}
  ${media.between("medium", "large")`
        margin-left: 0px;
  `}
`;

const ProjectTitle = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 26px;
    color: #000;
    ${media.lessThan("medium")`
    font-size: 20px;
  `}
`;

const AboutText = styled.p`
  /* width: 500px; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  justify-self: center;
  font-size: 16px;
  color: #000;
  line-height: 34px;
  margin-bottom: 0px;
  ${media.lessThan("medium")`
    font-size: 15px;
    width: 250px;
    margin-left: 0px;
    margin-top: 10px;
    line-height: 24px;
`}

  ${media.between("medium", "large")`
    font-size: 1.2em;
    margin-left: 0px;
    margin-top: 10px;
`}
`;

const DateText = styled(AboutText)`
    margin-top: 0px;
`;

const ImgPost = styled.img`
  /* border: 1px green dashed; */
    outline: none;
    z-index: 1;
    height: 300px;
    width: 300px;
    ${media.lessThan("medium")`
        width: 250px;
        height: auto;
        align-self: center;
        margin-bottom: 20px;
  `}
  ${media.between("medium", "large")`
        margin-bottom: 20px;
  `}
`;

const ImgPost2 = styled(ImgPost)`
    height: 200px;
    width: 200px;
`;

const ImgPostPortrait = styled(ImgPost)`
    height: auto;
    width: 200px;
`;


const Tag = styled.a`
  text-decoration: none;
  `;

const BtnView = styled.button`
    width: auto;
    height: 50px;
    background: #060b1d;
    border: 1px solid black;
    border-radius: 15px;
    /* border: none; */
    padding: 0px 20px;
    margin-top: 5px;
    outline: none;
    color: ${HBTurq};
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 1em;
    transition-duration: 0.5s;
    &:hover{
        /* opacity: 0.9; */
        transform: scale(1.1);
        }
    ${media.lessThan("medium")`
        align-self: center;
    `}
`;


export default class BlogLayout extends Component {
    render() {
        return (
            <Container>
                <PageTitle>OUR BLOG</PageTitle>
                <Card>
                        <ImgPost src={ImgCompMeme} />
                    <CardTextContainer>
                            <ProjectTitle>DO YOU HAVE A GREAT WEBSITE OR APP IDEA?</ProjectTitle>
                            <DateText>10 / 05 / 2021</DateText>
                            <AboutText> 
                            Do you have a great website or app idea? It may seem like a daunting task 
                            to transform an idea into technology but often you don't need too much to get 
                            started. Spheres Creative can help you get that idea out of your head and into a 
                            prototype so you can go find your users and see what they say. 
                            </AboutText>
                    </CardTextContainer>
                </Card>
                <Card2>
                        <ImgPost2 src={Img2} />
                    <CardTextContainer>
                            <ProjectTitle>COMPLEXITY MADE SIMPLE</ProjectTitle>
                            <DateText>09 / 24 / 2021</DateText>
                            <AboutText> 
                                Taking the road less travelled... where does it go?
                            </AboutText>
                    </CardTextContainer>
                </Card2>
                <Card2>
                    <ImgPostPortrait src={Img3} />
                    <CardTextContainer>
                        <ProjectTitle>HEAR BEYOND ANDROID BETA</ProjectTitle>
                        <DateText>09 / 17 / 2021</DateText>
                        <AboutText> 
                            It took all summer to code & climb a mountain of Android and we are so pleased to say
                            that a simple Hear Beyond Beta is now available on Google Play.
                            Download the Android Beta and come for a sonic adventure to discover the unknowns in and around
                            Auditory Brain Training - an emerging field of study discovering the vital connections between
                            the world of sound and what the hearing mind experiences... and can create.
                        </AboutText>
                    </CardTextContainer>
                </Card2>
                <Card2>
                    <ImgPost2 src={Img4} />
                    <CardTextContainer>
                        <ProjectTitle>GO</ProjectTitle>
                        <DateText>07 / 28 / 2021</DateText>
                        <AboutText> 
                            We're excited to announce the latest and greatest in Auditory Brain Training! We'd like to invite
                            you to to try the new Sessions.
                        </AboutText>
                    </CardTextContainer>
                </Card2>
                <Card2>
                    <ImgPost2 src={Img5} />
                    <CardTextContainer>
                        <ProjectTitle>SOUND IN NOISE</ProjectTitle>
                        <DateText>07 / 02 / 2021</DateText>
                        <AboutText> 
                            Sound in Noise is based on the ability to follow conversations in noisy environments. Your auditory
                            system is often required to process complex sounds embedded in background noise.
                        </AboutText>
                    </CardTextContainer>
                </Card2>
                <Card2>
                    <ImgPost2 src={Img6} />
                    <CardTextContainer>
                        <ProjectTitle>LOCALIZER 360</ProjectTitle>
                        <DateText>06 / 25 / 2021</DateText>
                        <AboutText> 
                            Hear Beyond's first developed tool was Localizer and is based on the process of sound localization.
                            Sound localization is the ability to determine the location of a sound in 3D space.
                        </AboutText>
                    </CardTextContainer>
                </Card2>
                <Card2>
                    <ImgPost2 src={Img7} />
                    <CardTextContainer>
                        <ProjectTitle>AUDITORY PLASTICITY</ProjectTitle>
                        <DateText>06 / 21 / 2021</DateText>
                        <AboutText> 
                            Did you know that your auditory brain is able to reorganize itself based on what you are hearing?
                            Auditory plasticity refers to your auditory brain's ability to change with experience and training.
                        </AboutText>
                    </CardTextContainer>
                </Card2>
                <Card2>
                    <ImgPost2 src={Img8} />
                    <CardTextContainer>
                        <ProjectTitle>ADD DEPTH AND DIMENSION TO YOUR LISTENING EXPERIENCE</ProjectTitle>
                        <DateText>06 / 11 / 2021</DateText>
                        <AboutText> 
                            We are excited to announce that Hear Beyond has been approved and is now available for download on the
                            App Store.
                        </AboutText>
                    </CardTextContainer>
                </Card2>
                <Card2>
                    <ImgPost2 src={Img9} />
                    <CardTextContainer>
                        <ProjectTitle>HOW FAR CAN THE RELATIONSHIP BETWEEN YOUR BRAIN AND EARS BE OPTIMIZED?</ProjectTitle>
                        <DateText>06 / 11 / 2021</DateText>
                        <AboutText> 
                            We are excited to announce that Hear Beyond has been approved and is now available for download on the
                            App Store.
                        </AboutText>
                    </CardTextContainer>
                </Card2>
                <Card2>
                    <ImgPost2 src={Img10} />
                    <CardTextContainer>
                        <ProjectTitle>HEAR BEYOND IOS BETA RELEASE</ProjectTitle>
                        <DateText>05 / 14 / 2021</DateText>
                        <AboutText> 
                            Hear Beyond is now live and open for beta testing on iOS! Have you ever wondered what your ears are truly
                            capable of? If trained, what depth and detail could be discovered in your favourite songs?
                        </AboutText>
                    </CardTextContainer>
                </Card2>
            </Container>
        )
    }
}
