import React, { Component, useState } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import { HBTurq } from './../../../../config/theme';

const Button = styled.button`
    outline: none;
    width: 90%;
    height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-content: center;
    margin: 15px 0px;
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 10px;
    box-shadow: 0px 0px 10px ${HBTurq};
    ${media.lessThan("medium")`
    margin-top: 15px;
    `}
    ${media.between("medium", "large")`
      margin-top: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const PressedButton = styled(Button)`
    /* border: 3px solid ${props => 'blue' || 'blue' }; */
    border: 3px solid blue;
`;

const ButtonText = styled.p`
  /* border: 1px red dashed; */
    width: auto;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 15px;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    align-self: center;
    justify-self: center;

`;

const Icon = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 3px;
    align-self: center;
    ${media.lessThan("medium")`
    `}
    ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`;

export default function SurveyButton(props) {

    const [isPressed, setIsPressed] = useState(false)

        const handleAnswer = () => {
            if(isPressed === false){
                // add answer to array
                setIsPressed(true)
                props.add(props.answer)
            } else {
                // remove answer from array
                setIsPressed(false)
                props.remove(props.answer)
            }       
    }

    return (
        <div>
            { isPressed ? 
            <PressedButton onClick={handleAnswer}>
                <Icon src={props.image}></Icon>
                    <ButtonText>
                        {props.answer}
                    </ButtonText>
            </PressedButton>
                :
            <Button onClick={handleAnswer}>
                <Icon src={props.image}></Icon>
                    <ButtonText>
                        {props.answer}
                    </ButtonText>
            </Button>
        }
        </div>
    )
}
