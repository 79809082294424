import React, { Component } from 'react'
import SurveyButton from '../Common/SurveyButton'
import SurveyInput from '../Common/SurveyInput'
import {Container, ProjectTitle, InstructionText} from './../Common/SurveyStyles'
import image from './../../../../res/images/icon_a.png';
import imageb from './../../../../res/images/icon_b.png';
import imagec from './../../../../res/images/icon_c.png';
import imaged from './../../../../res/images/icon_d.png';

export default class Step2 extends Component  {

   q = "What games do you want to see next?"
   a = "Ear Training"
   b = "Hearing Loss"
   c = "Listen to voices in noise"
   d = "Audio Production"
   e = "Other"

   state = {
     finalAnswers: [],
     aPressed: false,
     bPressed: false,
     cPressed: false,
     dPressed: false,
     userInput: ""
   }

   componentWillReceiveProps(nextProps) {
    if (nextProps.shouldSend !== this.props.shouldSend && nextProps.shouldSend === 3) {
      this.sendAnswer()
    }
}

   addAnswer = (answer) => {
    let answersArray = this.state.finalAnswers
    answersArray.push(answer)
    this.setState({ finalAnswers : answersArray})
  }

  removeAnswer = (answer) => {
    let answersArray = this.state.finalAnswers
    const index = answersArray.indexOf(answer);
    if (index > -1) {
      answersArray.splice(index, 1);
      this.setState({ finalAnswers : answersArray})
    }
  }

  passInput = (input) => {
    // pass this into input to get it's data
    this.setState({ userInput : input})
  }

   sendAnswer = () => {
    // Wrap up question, answers and custom input for pass to parent
    let answersArray = this.state.finalAnswers
    answersArray.unshift(this.q)
    answersArray.push(this.state.userInput)
    this.props.answer(answersArray)
  }

  render() {
    return (
        <div>
            <Container>
                <ProjectTitle>Why did you download Hear Beyond?</ProjectTitle>
                <InstructionText>Choose as many as you like:</InstructionText>
                <SurveyButton image={image} answer={this.a} add={this.addAnswer} remove={this.removeAnswer}></SurveyButton>
                <SurveyButton image={imageb} answer={this.b} add={this.addAnswer} remove={this.removeAnswer}></SurveyButton>
                <SurveyButton image={imagec} answer={this.c} add={this.addAnswer} remove={this.removeAnswer}></SurveyButton>
                <SurveyButton image={imaged} answer={this.d} add={this.addAnswer} remove={this.removeAnswer}></SurveyButton>
                <SurveyInput input={this.state.userInput} pass={this.passInput} >Other</SurveyInput>
            </Container>
        </div>
    )
  }
}
