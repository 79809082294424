import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import MarqueeSlider from './MarqueeSlider';
import ImgIcon from "./../../../res/images/ic_quote.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { HBTurq } from '../../../config/theme';


const Grid = styled.div`
  /* border: 1px yellow dashed; */
  grid-area: 5 / 1 / 6 / 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: linear-gradient(#15b1b2 0%, #0b5959 100%);
  /* padding-top: 60px; */
  height: 100%;
  padding: 35px;
  ${media.lessThan("medium")`
  padding-top: 40px;
  `}

  ${media.between("medium", "large")`
  `}

  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  height: auto;
  max-width: 1440px;
  padding: 35px;
  width: 100%;
  flex-direction: column;
  align-self: center;
  ${media.lessThan("medium")`
    justify-content: center;
    padding-top: 60px;
  `}
  ${media.between("medium", "large")`
    padding-top: 60px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const KeywordText = styled.h2`
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  justify-self: center;
  font-size: 20px;
  color: white;
  margin-bottom: 20px;
  ${media.lessThan("medium")`
    font-size: 15px;
`}
  ${media.between("medium", "large")`
`}
`;

const TitleText = styled.h1`
  max-width: 900px;
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: black;
  justify-self: center;
  font-size: 30px;
  line-height: 50px;
  ${media.lessThan("medium")`
  font-size: 20px;
`}
  ${media.between("medium", "large")`
`}
`;

const CardContainer = styled.div`
  /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
    max-width: 1440px;
    /* padding: 35px; */
    ${media.lessThan("medium")`
        flex-direction: column;
        align-content: center;
        margin-top: 0px;
    `}
    ${media.between("medium", "large")`
    `}
`;

const CardTextContainer = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    flex-direction: row;
    max-width: 1440px;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
    ${media.lessThan("medium")`
        flex-direction: column;
        padding-top: 0px;
        padding-bottom: 0px;
    `}
    ${media.between("medium", "large")`
    `}
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 38px;
    padding: 20px;
    width: 29%;
    ${media.lessThan("medium")`
        width: 320px;
        align-self: center;
        margin-top: 20px;
        padding: 20px;
  `}
    ${media.between("medium", "large")`
        padding: 10px;
  `}
`;

const Icon = styled.img`
    width: 60px;
    height: 60px;
    align-self: center;
`;

const CardTitleText = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    color: white;
    margin-top: 30px;
    line-height: 38px;
    ${media.lessThan("medium")`
    `}
    ${media.between("medium", "large")`
    font-size: 18px;
    line-height: 24px;
    `}
`;

const CardText = styled.p`
    font-family: Montserrat;
    font-weight: 400;
    font-size: 15px;
    line-height: 50px;
    text-align: center;
    color: white;
    line-height: 38px;
    ${media.lessThan("medium")`
  `}
    ${media.between("medium", "large")`
    line-height: 30px;
  `}
`;

export default class ClientTestimonials extends Component {

  state = {
        move: false
    }

    componentDidMount(){
        Aos.init()

        setTimeout(function() {
            var resizeEvent = new Event("resize");
            window.dispatchEvent(resizeEvent);
        }.bind(this), 1000)

      }

  render() {
  return (
        <Grid>
                <TitleContainer>
                    <KeywordText>CLIENT TESTIMONIALS</KeywordText>
                </TitleContainer>
          <CardContainer>
            <CardTextContainer>
                <Card
                  data-aos="fade-in" 
                  data-aos-duration="1000" 
                  data-aos-easing="ease-in-sine"
                >
                    <Icon src={ImgIcon} />
                    <CardTitleText>FUN & INCREDIBLY EFFECTIVE</CardTitleText>
                    <CardText>
                        Achieve and maintain a healthier lifestyle inside and out Achieve and 
                        maintain a healthier lifestyle inside and out Achieve and maintain a healthier lifestyle 
                        inside and out. 
                    </CardText>
                    <CardText>- Stephen Evans</CardText>
                </Card>
                <Card
                  data-aos="fade-in" 
                  data-aos-duration="1000" 
                  data-aos-easing="ease-in-sine"
                >
                    <Icon src={ImgIcon} />
                    <CardTitleText>I CAN'T RECOMMEND THEM ENOUGH!</CardTitleText>
                    <CardText>
                        Achieve and maintain a healthier lifestyle inside and out Achieve and 
                        maintain a healthier lifestyle inside and out Achieve and maintain a healthier lifestyle 
                        inside and out Achieve and maintain a healthier lifestyle inside and out Achieve and maintain a healthier 
                        lifestyle inside and out.
                    </CardText>
                    <CardText>- Taylor Jenkins</CardText>
                </Card>
                <Card
                  data-aos="fade-in" 
                  data-aos-duration="1000" 
                  data-aos-easing="ease-in-sine"
                >
                    <Icon src={ImgIcon} />
                    <CardTitleText>EVERYONE IS WELCOME HERE!</CardTitleText>
                    <CardText>
                        Achieve and maintain a healthier lifestyle inside and out Achieve and 
                        maintain a healthier lifestyle inside and out.
                    </CardText>
                    <CardText>- Wendy Parker</CardText>
                </Card>
            </CardTextContainer>
          </CardContainer>
          </Grid>
          )
        }
      }
      