import React, { Component } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";
import { HBTurq } from "../../../config/theme";
import { SocialIcon } from 'react-social-icons';
import TagManager from 'react-gtm-module'
import { HashLink } from 'react-router-hash-link';


const Background = styled.div`
 flex-direction: column;
 align-content: center;
 justify-content: center;
 display: flex;
 /* background: black; */
 /* border-top: 1px ${HBTurq} solid; */
`;

const OuterContainer = styled.div`
    display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-content: center;
  /* background: black; */
`;


const IconContainer = styled.div`
/* border: 1px green dashed; */

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
  width: 300px;
  height: 60px;
  padding-top: 5px;
`;


const CopyrightText = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  width: 550px;
  /* margin-bottom: 30px; */
  text-shadow: none;
  justify-self: center;
  font-size: 0.8em;
  color: #CBCBCB;
  line-height: 38px;
  align-self: center;
    text-align: center;

  ${media.lessThan("medium")`
    width: 300px;
    line-height: 28px;

`}

  ${media.between("medium", "large")`
    width: 400px;

`}
`;

const BtnView = styled.button`
    width: 200px;
    height: 50px;
    background: transparent;
    border: none;
    margin-top: 20px;
    outline: none;
    color: ${HBTurq};
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 1em;
    align-self: center;
    margin-bottom: 20px;
    transition-duration: 0.5s;
    &:hover{
        opacity: 0.9;
        transform: scale(1.2);
        }
    ${media.lessThan("medium")`
        margin-left: 20px;
    `}
`;

const SocialIconContainer = styled.div`
    transition-duration: 0.5s;
      &:hover{
        opacity: 0.9;
        transform: scale(1.2);
        }
`;

export default class Footer extends Component {

    componentDidMount(){
            Aos.init({ duration: 2000 });
          }

          handleFacebookLink = () => {
            TagManager.initialize(tagFacebookLink)
          }
          handleInstagramLink = () => {
            TagManager.initialize(tagInstagramLink)
          }
          handleLinkedInLink = () => {
            TagManager.initialize(tagLinkedInLink)
          }
          handleTwitterLink = () => {
            TagManager.initialize(tagTwitterLink)
          }
    
  render() {
    return (
        <Background>
          <OuterContainer>
              <HashLink to="#top"  style={{ textDecoration: 'none', alignSelf: 'center' }}>
                  <BtnView>Back to Top</BtnView>
              </HashLink>
            <IconContainer>
              <SocialIconContainer onClick={this.handleFacebookLink}><SocialIcon target="_blank"  url="http://facebook.com/spherescreative"  bgColor={HBTurq} style={{height:'40px', width:'40px', marginRight:'10px'}}/></SocialIconContainer>
              <SocialIconContainer onClick={this.handleInstagramLink} ><SocialIcon target="_blank"  url="http://instagram.com/spherescreative" bgColor={HBTurq} style={{height:'40px', width:'40px', marginRight:'10px'}}/></SocialIconContainer>
              <SocialIconContainer onClick={this.handleTwitterLink} ><SocialIcon target="_blank" url="http://twitter.com/spherescreative"  bgColor={HBTurq} style={{height:'40px', width:'40px', marginRight:'10px'}}/></SocialIconContainer>
              <SocialIconContainer onClick={this.handleLinkedInLink}><SocialIcon target="_blank"  url="http://linkedin.com/company/spherescreative"  bgColor={HBTurq} style={{height:'40px', width:'40px', marginRight:'10px'}}/></SocialIconContainer>
            </IconContainer>
            <CopyrightText> Copyright © 2022 Spheres Creative Inc. All rights reserved.</CopyrightText>
          </OuterContainer>
        </Background>
    )
  }
}


const tagFacebookLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'hearbeyond_link_facebook'
  }
}

const tagInstagramLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'hearbeyond_link_instagram'
  }
}

const tagTwitterLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'hearbeyond_link_twitter'
  }
}

const tagLinkedInLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'hearbeyond_link_linkedin'
  }
}