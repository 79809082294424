import React from 'react'
import styled from 'styled-components'
import Marquee from "react-marquee-slider";
import media from "styled-media-query";
import Image from "./../../../res/images/TickerTape1.png";
import Image2 from "./../../../res/images/TickerTape2.png";
import Image3 from "./../../../res/images/TickerTape3.png";

const SliderImage = styled.img`
    height: 180px;
    width: auto;
    margin-top: 30px;
    margin-right: 22px;
    ${media.lessThan("medium")`
        height: 150px;
    `}
    ${media.between("medium", "large")`
    `}
`;

function MarqueeSlider(){

    var Photos = [Image, Image2, Image3]
    var Photos2 = [Image2, Image3, Image]
    var Photos3 = [Image2, Image3, Image]

    return (
        <div  >
            <Marquee 
            velocity={3}
            >
            {
                Photos.map(item => (
                    <SliderImage key="0" src={item}/>
                    ))
                }
            </Marquee>
            <Marquee 
            velocity={3}
            direction="clockwise"
            >
            {
                Photos3.map(item => (
                    <SliderImage key="1" src={item}/>
                    ))
                }
            </Marquee>
            {/* <Marquee 
            velocity={5}
            >
            {
                Photos2.map(item => (
                    <SliderImage key="0" src={item}/>
                    ))
                }
            </Marquee> */}
        </div>
    )
}
 
export default MarqueeSlider