import React, { Component } from 'react'
import styled from 'styled-components'
import media from "styled-media-query"
import { HBTurq } from "../../../config/theme";
import Image from "./../../../res/images/iPhone_Intro_Localizer.png";
import Image2 from "./../../../res/images/iPhone_Stats_Localizer.png";
import Image3 from "./../../../res/images/iPhone_SoundLibrary.png";
import Image4 from "./../../../res/images/iPhone_Gameplay_Localizer.png";
import Image5 from "./../../../res/images/iPhone_Intro_Session.png";
import Image6 from "./../../../res/images/iPhone_Soundcheck_Soundmatch.png";
import ImageSpheres from "./../../../res/images/web_SpheresCreative.jpg";
import ImageWingnut from "./../../../res/images/web_Wingnut.jpg";
import ImageYama from "./../../../res/images/web_Yama.jpg";
import ImageKousk from "./../../../res/images/web_Kousk.jpg";
import TagManager from 'react-gtm-module'

const Container = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    `;

const RightContainer = styled.div`
    /* border: 1px red dashed; */
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-self: center;
    justify-self: center;
    flex-wrap: wrap;
    margin-top: 50px;
    /* margin-bottom: 100px; */
`;

const Card = styled.div`
    display: flex;
    flex-direction: row;
    width: 580px;
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 20px;
    background: radial-gradient(#0a276a 0%, #060b1d 100%);
    margin-bottom: 40px;
    justify-content: space-evenly;
    ${media.lessThan("medium")`
        width: 90%;
  `}
`;

// TEXT

const Title = styled.h3`
    /* border: 1px green dashed; */
  font-family: 'Montserrat', sans-serif;
  font-size: 2.2em;
  font-weight: 400;
  line-height: 40px;
  z-index: 1;
  color: white;
  text-shadow: 1px 1px 4px #000;
  justify-self: center;
  text-align: center;
  align-self: center;
  /* margin-bottom: 20px; */
  padding-top: 50px;
  ${media.lessThan("medium")`
  font-size: 1.5em;
  `}

  ${media.between("medium", "large")`
  align-self: center;
  `}
`;

const CardTextContainer = styled.div`
    /* border: 1px green dashed; */
    height: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    ${media.lessThan("medium")`
        width: 80%;
  `}
`;

const ProjectTitle = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 0px;
    align-self: center;
`;



const Tag = styled.a`
  text-decoration: none;
  align-self: center;
`;

const BtnView = styled.button`
    width: 200px;
    height: 50px;
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 30px;
    box-shadow: 0px 0px 5px ${HBTurq};
    margin-top: 20px;
    outline: none;
    color: ${HBTurq};
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 1em;
    transition-duration: 0.5s;
    &:hover{
        background-color: #6EFAFF;
        color: #000;
        opacity: 0.9;
        transform: scale(0.98);
        }
    ${media.lessThan("medium")`
        /* margin-left: 20px; */
        align-self: center;

    `}
`;


//  IMAGES


const ImgContainer = styled.div`
    /* border: 1px green dashed; */
    width: auto;
    height: 249px;
    align-self: center;
    border-radius: 11px;
    align-content: center;
    padding-right: 15px;
    ${media.lessThan("medium")`
        width: auto;
        height: auto;
        align-content: center;
        padding: 0px 0px;
        /* align-self: start; */
        /* margin-left: 20px; */
  `}
`;

const ImgPhone = styled.img`
    outline: none;
    z-index: 1;
    height: 240px;
    width: 120px;
    margin-right: 15px;
    /* margin-left: 15px; */
    ${media.lessThan("medium")`
        height: auto;
        width: 60px;
        margin-right: 2px;
        margin-left: 2px;
  `}
`;

const ImgWeb = styled.img`
  /* border: 1px green dashed; */
    outline: none;
    z-index: 1;
    height: auto;
    width: 420px;
    ${media.lessThan("medium")`
        width: 250px;
        height: auto;
        align-self: center;
  `}
`;

const BtnContact = styled.button`
    width: 445px;
    height: 100px;
    border: 2px solid ${HBTurq};
    box-sizing: border-box;
    border-radius: 65px;
    background: none;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    line-height: 129.5%;
    text-align: center;
    letter-spacing: 0.05em;
    color: ${HBTurq};
    align-self: center;
    margin-bottom: 80px;
    margin-top: 40px;
    transition-duration: 0.5s;
    &:hover{
        background-color: #6EFAFF;
        color: #000;
        opacity: 0.9;
        transform: scale(0.98);
        }
`;



export default class ServicesLayout2 extends Component {

    componentDidMount(){
        TagManager.initialize(tagPageVisit)
    }

    handleWingnutLink = () => {
        TagManager.initialize(tagWingnutLink)
    }
    handleYamaLink = () => {
        TagManager.initialize(tagYamaLink)
    }
    handleiOSLink = () => {
        TagManager.initialize(tagiOSLink)
    }
    handleAndroidLink = () => {
        TagManager.initialize(tagAndroidLink)
    }
    handleSpheresLink = () => {
        TagManager.initialize(tagSpheresLink)
    }
    handleKouskLink = () => {
        TagManager.initialize(tagKouskLink)
    }

    render() {
        return (
            <Container>
                <Title>Software Development Services</Title>
                <RightContainer>

                    <Card>
                        <CardTextContainer>
                        <ImgWeb src={ImageWingnut} />
                            <ProjectTitle>Wingnut IoT</ProjectTitle>
                                <Tag
                                    target="_blank" 
                                    href="https://wingnutiot.ca/">
                                    <BtnView onClick={this.handleWingnutLink}>VIEW</BtnView>
                                </Tag>
                        </CardTextContainer>
                    </Card>
                    
                    <Card>
                        <CardTextContainer>
                        <ImgWeb src={ImageYama} />
                            <ProjectTitle>Yama</ProjectTitle>
                                <Tag
                                    target="_blank" 
                                    href="https://getyama.ca">
                                    <BtnView onClick={this.handleYamaLink}>VIEW</BtnView>
                                </Tag>
                        </CardTextContainer>
                    </Card>

                    <Card>
                        <CardTextContainer>
                            <ImgContainer>
                                <ImgPhone src={Image} />
                                <ImgPhone src={Image2} />
                                <ImgPhone src={Image3} />
                            </ImgContainer>
                            <ProjectTitle>Hear Beyond iOS</ProjectTitle>
                            <Tag
                                target="_blank" 
                                href="https://apps.apple.com/ca/app/hear-beyond/id1529830800">
                                <BtnView onClick={this.handleiOSLink}>VIEW</BtnView>
                            </Tag>
                        </CardTextContainer>
                    </Card>

                    <Card>
                        <CardTextContainer>
                            <ImgContainer>
                                <ImgPhone src={Image4} />
                                <ImgPhone src={Image5} />
                                <ImgPhone src={Image6} />
                            </ImgContainer>
                            <ProjectTitle>Hear Beyond Android</ProjectTitle>
                            <Tag
                                target="_blank" 
                                href="https://play.google.com/store/apps/details?id=com.hearbeyondandroid">
                                <BtnView onClick={this.handleAndroidLink}>VIEW</BtnView>
                            </Tag>
                        </CardTextContainer>
                    </Card>



                    <Card>
                        <CardTextContainer>
                            <ImgWeb src={ImageSpheres} />
                            <ProjectTitle>Spheres Creative</ProjectTitle>
                                <Tag
                                    target="_blank" 
                                    href="https://www.spherescreative.com">
                                    <BtnView onClick={this.handleSpheresLink}>VIEW</BtnView>
                                </Tag>
                        </CardTextContainer>
                    </Card>

                    <Card>
                        <CardTextContainer>
                        <ImgWeb src={ImageKousk} />
                            <ProjectTitle>Kousk</ProjectTitle>
                                <Tag
                                        target="_blank" 
                                        href="https://www.kouskmusic.com">
                                    <BtnView onClick={this.handleKouskLink}>VIEW</BtnView>
                                </Tag>
                        </CardTextContainer>
                    </Card>
                </RightContainer>
            </Container>

        )
    }
}

const tagPageVisit = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'services_page_visit'
      }
    }

const tagWingnutLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'service_link_wingnut'
  }
}

const tagYamaLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'service_link_yama'
  }
}

const tagiOSLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'service_link_ios_hb'
  }
}

const tagAndroidLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'service_link_android_hb'
  }
}

const tagSpheresLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'service_link_spheres'
  }
}

const tagKouskLink = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'service_link_kousk'
  }
}