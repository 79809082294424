import React, { Component } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import SurveyButton, { SetState } from '../Common/SurveyButton'
import SurveyInput from '../Common/SurveyInput'
import SurveyYesNoButton from '../Common/SurveyYesNoButton'
import { HBTurq } from '../../../../config/theme';
import {Container, ProjectTitle, InstructionText} from './../Common/SurveyStyles'
import image from './../../../../res/images/icon_a.png';
import imageb from './../../../../res/images/icon_b.png';
import imagec from './../../../../res/images/icon_c.png';
import imaged from './../../../../res/images/icon_d.png';

const ButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.button`
    outline: none;
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: row;
    margin: 15px 0px;
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 10px;
    box-shadow: 0px 0px 10px ${HBTurq};
    ${media.lessThan("medium")`
    margin-top: 15px;
    /* width: 90%; */
    `}
    ${media.between("medium", "large")`
      margin-top: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const HiddenYesNo = styled.div`
    width: 150px;
    height: 50px;
`;

export default class Step3 extends Component {

    q = "Did you purchase a subscription?"
    a = "Too expensive"
    b = "Wasn't going to use it"
    c = "Waiting for more content"
    e = "Other"

    state = {
      finalAnswers: [],
      aPressed: false,
      bPressed: false,
      cPressed: false,
      dPressed: false,
      userInput: "",
      userConditional: null,
      yesPressed: false,
      noPressed: false
    }

    componentWillReceiveProps(nextProps) {
    if (nextProps.shouldSend !== this.props.shouldSend && nextProps.shouldSend === 4) {
      this.sendAnswer()
    }
  }

    setUserConditional = (answer) => {
        this.setState({ userConditional : answer})
    }


    addAnswer = (answer) => {
    let answersArray = this.state.finalAnswers
    answersArray.push(answer)
    this.setState({ finalAnswers : answersArray})
  }

  removeAnswer = (answer) => {
    let answersArray = this.state.finalAnswers
    const index = answersArray.indexOf(answer);
    if (index > -1) {
      answersArray.splice(index, 1);
      this.setState({ finalAnswers : answersArray})
    }
  }

  passInput = (input) => {
    // pass this into input to get it's data
    this.setState({ userInput : input})
  }

    sendAnswer = () => {
    // Wrap up question, answers and custom input for pass to parent
    let answersArray = this.state.finalAnswers
    answersArray.unshift(this.state.userConditional)
    answersArray.unshift(this.q)
    if(this.state.userConditional === false){
      answersArray.push(this.state.userInput)
    }
    this.props.answer(answersArray)
  }



  render(){
    let yesButton
    let noButton

    // Hide or unhide opposite button when selecting
    if(this.state.userConditional === true){
      yesButton = <SurveyYesNoButton image={image} answer={'Yes'} conditional={this.state.userConditional} setConditional={this.setUserConditional}/>
      noButton = <HiddenYesNo></HiddenYesNo>
    } else if(this.state.userConditional === false) {
      yesButton = <HiddenYesNo></HiddenYesNo>
      noButton = <SurveyYesNoButton image={imageb} answer={'No'} conditional={!this.state.userConditional} setConditional={this.setUserConditional}/>
    } else {
      yesButton = <SurveyYesNoButton image={image} answer={'Yes'} conditional={this.state.userConditional} setConditional={this.setUserConditional}/>
      noButton = <SurveyYesNoButton image={imageb} answer={'No'} conditional={!this.state.userConditional} setConditional={this.setUserConditional}/>
    }

    return (
      <div>
            <Container>
            <ProjectTitle>{this.q}</ProjectTitle>
            <ButtonRowContainer>
              {yesButton}
              {noButton}
         </ButtonRowContainer>

         {this.state.userConditional === null ?
            null
         :
         
          this.state.userConditional === false ? 
            <div>
              <InstructionText>May we ask why?</InstructionText>
              <SurveyButton image={image} answer={this.a} add={this.addAnswer} remove={this.removeAnswer}></SurveyButton>
              <SurveyButton image={imageb} answer={this.b} add={this.addAnswer} remove={this.removeAnswer}></SurveyButton>
              <SurveyButton image={imagec} answer={this.c} add={this.addAnswer} remove={this.removeAnswer}></SurveyButton>
              <SurveyInput input={this.state.userInput} pass={this.passInput} >Other</SurveyInput>
            </div>
            :
            <InstructionText>Thank you for supporting Hear Beyond</InstructionText>
         }




               
            </Container>
        </div>
    )
  }
}
