import styled from 'styled-components'
import media from "styled-media-query";
import { HBTurq } from '../../../../config/theme';

// Common components for the Steps (Questions & Answers)

export const Container = styled.div`
  /* border: 1px dashed yellow; */
  max-width: 500px;
  height: 450px;
  margin: 50px auto;
  ${media.lessThan("medium")`
    width: 350px;
    `}
`
export const ProjectTitle = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
    margin-bottom: 20px;
    align-self: center;
`;

export const InstructionText = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 10px;
    align-self: center;
`;
