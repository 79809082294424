import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
// import TrainYourEars from './TrainYourEars';
import ImgIcon from "./../../../res/images/ic_laptop.svg";
import ImgIcon2 from "./../../../res/images/ic_tablet.svg";
import ImgIcon3 from "./../../../res/images/ic_phone.svg";
import ImgIcon4 from "./../../../res/images/ic_watch.svg";
import ImgIcon5 from "./../../../res/images/ic_web.svg";
import ImgIcon6 from "./../../../res/images/ic_server.svg";
import ImgIcon7 from "./../../../res/images/ic_database.svg";
import ImgBtnIcon from "./../../../res/images/ic_arrow_right.svg";
import TagManager from 'react-gtm-module'
import { HBTurq } from '../../../config/theme';

const Grid = styled.div`
  /* border: 1px red dashed; */
  grid-area: 4 / 1 / 5 / 2;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* padding-bottom: 120px; */
  background-color: white;
  ${media.lessThan("medium")`
  padding-left: 0px;
;  `}
  ${media.between("medium", "large")`
    padding-left: 0px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const Container = styled.div`
  /* border: 1px red dashed; */
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 35px;
  ${media.lessThan("medium")`
;  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;


const TitleContainer = styled.div`
  /* border: 1px blue dashed; */
  max-width: 1440px;
  display: flex;
  height: auto;
  flex-direction: column;
  padding-top: 80px;
  ${media.lessThan("medium")`
    justify-content: center;
    padding-top: 60px;
  `}
  ${media.between("medium", "large")`
    padding-top: 60px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const KeywordText = styled.h2`
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  justify-self: center;
  font-size: 20px;
  color: #15b1b2;
  margin-bottom: 20px;
  ${media.lessThan("medium")`
    font-size: 15px;
`}
  ${media.between("medium", "large")`
`}
`;

const TitleText = styled.h1`
  max-width: 900px;
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: black;
  justify-self: center;
  font-size: 30px;
  line-height: 50px;
  ${media.lessThan("medium")`
  font-size: 20px;
`}
  ${media.between("medium", "large")`
`}
`;

const CardContainer = styled.div`
    /* border: 2px green dashed; */
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    margin-top: 80px;
    ${media.lessThan("medium")`
    flex-direction: column;
    `}
  ${media.between("medium", "large")`
    flex-direction: column;
    `}
`;

const CardRowContainer = styled.div`
    /* border: 2px yellow dashed; */
    display: flex;
    flex-direction: row;
    width: 50%;
    ${media.lessThan("medium")`
        flex-direction: column;
        width: 100%;
    `}
  ${media.between("medium", "large")`
        flex-direction: row;
        width: 100%;
    `}
`;

const Card = styled.div`
    /* border: 2px red dashed; */
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;
    justify-content: space-between;
    ${media.lessThan("medium")`
    width: 100%;
    flex-direction: column;
    `}
  ${media.between("medium", "large")`
    width: 100%;
    flex-direction: column;
    `}
`;

const Icon = styled.img`
    width: 80px;
    height: 80px;
`;

const CardTitleContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    width: 25%;
    ${media.lessThan("medium")`
        width: 100%;
;`}
${media.between("medium", "large")`
        width: 100%;     
`}
`;

const CardNumber = styled.h1`
    /* border: 1px red dashed; */
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    width: 50px;
    text-align: left;
    color: #000;
    ${media.lessThan("medium")`
    ;`}
    ${media.between("medium", "large")`
`}
`;

const CardTitleColumn = styled.div`
    /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CardTitle = styled.h1`
    /* border: 1px blue dashed; */
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    /* width: 200px; */
    text-align: left;
    color: #000;
    ${media.lessThan("medium")`
    /* width: 60%; */
;`}
`;

const CardText = styled.p`
    /* border: 1px green dashed; */
    font-family: Montserrat;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #000;
    width: 50%;
    line-height: 32px;
    ${media.lessThan("medium")`
    width: 80%;
    margin-left: 42px;
;`}
${media.between("medium", "large")`
    width: 80%;     
    margin-top: 10px;
    margin-left: 50px;
`}
`;

const BtnLearnHow = styled.button`
  /* border: 1px green dashed; */
  width: 160px;
  height: 55px;
  border: 2px #15B1B2 solid;
  background: white;
  border-radius: 9px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  justify-content: center;
  align-content: center;
  margin-left: 40px;
  ${media.lessThan("medium")`
  margin-top: 20px;
  height: 50px;
  margin-left: 42px;
`}

  ${media.between("medium", "large")`
  margin-top: 20px;
  margin-left: 0px;
`}
`;

const ArrowIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 10px;
  align-self: center;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  margin-top: 14px;
  ${media.lessThan("medium")`
    padding-top: 10px;
    padding-bottom: 15px;
`}
  ${media.between("medium", "large")`
  padding-top: 10px;
    padding-bottom: 15px;
`}
`;

const LaptopIcon = styled.img`
    width: auto;
    height: 32px;
    margin-right: 10px;
`;

const TabletIcon = styled.img`
    width: 30px;
    height: 32px;
    margin-right: 5px;
`;

const PhoneIcon = styled.img`
    width: auto;
    height: 32px;
`;

const Line = styled.div`
  /* width: 97.75%; */
  height: 1px;
  background: #4c628c;
  margin-bottom: 50px;
  /* margin-right: 30px; */
  ${media.lessThan("767px")`
        /* align-self: center; */
        /* margin-right: 0px; */
    `}
`

const PrivacyTag = styled.a`
  text-decoration: none;
  ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
`}
`;

export default class HowWeHelp extends Component {

  componentDidMount(){
    // TagManager.initialize(tagPageVisit)
  }

  handleiOSLink = () => {
    TagManager.initialize(tagiOSLink)
  }
  handleAndroidLink = () => {
    TagManager.initialize(tagAndroidLink)
  }

  handleReactLink = () => {
    TagManager.initialize(tagReactLink)
  }
  handleAWSLink = () => {
    TagManager.initialize(tagAWSLink)
  }

  render() {
  
    return (
        <Grid>
            <Container>
                <TitleContainer>
                    <KeywordText id='products'>OUR PRODUCTS</KeywordText>
                    <TitleText>
                        Where Will Your App Live?
                    </TitleText>
                </TitleContainer>
                <CardContainer>
                        <Card>
                            <CardTitleContainer>
                                <CardNumber>01</CardNumber>
                                <CardTitleColumn>
                                  <CardTitle>Apple Store</CardTitle>
                                  <IconContainer>
                                    <LaptopIcon src={ImgIcon} />
                                    <TabletIcon src={ImgIcon2} />
                                    <TabletIcon src={ImgIcon3} />
                                    <PhoneIcon src={ImgIcon4} />
                                  </IconContainer>
                                </CardTitleColumn>
                            </CardTitleContainer>
                            <CardText>
                            Create an app using Swift, the coding language of Apple's many devices. Access the features of iPhones, iPads, Macbooks or an Apple Watch. Deploy your app to the Apple Store with reach of more than half a billion users each week.
                            </CardText>
                            <PrivacyTag href="https://developer.apple.com/swift/" target="_blank">
                              <BtnLearnHow>
                                  Learn more
                              </BtnLearnHow>
                            </PrivacyTag>
                        </Card>
                        <Line />
                        <Card>
                            <CardTitleContainer>
                                <CardNumber>02</CardNumber>
                                <CardTitleColumn>
                                  <CardTitle>Google Play</CardTitle>
                                  <IconContainer>
                                    <TabletIcon src={ImgIcon2} />
                                    <TabletIcon src={ImgIcon3} />
                                  </IconContainer>
                                </CardTitleColumn>
                            </CardTitleContainer>
                            <CardText>
                            Create an app using Kotlin, the coding language of the Android operating system. Develop for the latest Samsung, Galaxies and deploy the app to the Google Play store with a reach of half a billion users a week. 
                            </CardText>
                            <PrivacyTag href="https://developer.android.com/kotlin" target="_blank">
                              <BtnLearnHow>
                              Learn more
                              </BtnLearnHow>
                            </PrivacyTag>
                        </Card>
                        <Line />
                        <Card>
                            <CardTitleContainer>
                                <CardNumber>03</CardNumber>
                                <CardTitleColumn>
                                  <CardTitle>Website</CardTitle>
                                  <IconContainer>
                                    <LaptopIcon src={ImgIcon5} />
                                    <TabletIcon src={ImgIcon2} />
                                    <TabletIcon src={ImgIcon3} />
                                  </IconContainer>
                                </CardTitleColumn>
                            </CardTitleContainer>
                            <CardText>
                            Anyone can make a website so create a custom, flexible and responsive website that catches the eye and gets you those clicks. We use a powerful library called React which was made by Facebook for the job. 
                            </CardText>
                            <PrivacyTag href="https://reactjs.org/" target="_blank">
                              <BtnLearnHow>
                              Learn more
                              </BtnLearnHow>
                            </PrivacyTag>
                        </Card>
                        <Line />
                        <Card>
                          <CardTitleContainer>
                               <CardNumber>04</CardNumber>
                               <CardTitleColumn>
                                  <CardTitle>Backend Development</CardTitle>
                                  <IconContainer>
                                    <LaptopIcon src={ImgIcon6} />
                                    <PhoneIcon src={ImgIcon7} />
                                  </IconContainer>
                                </CardTitleColumn>
                          </CardTitleContainer>
                            <CardText>
                            Backend services are everything about software that you don't see on a phone or a browser. The backend consists of databases and servers used to store all the data associated with an app. We rely on the mighty Amazon Web Services to host our backends.
                            </CardText>
                            <PrivacyTag href="https://www.youtube.com/watch?v=a9__D53WsUs&ab_channel=AmazonWebServices" target="_blank">
                              <BtnLearnHow>
                              Learn more
                            </BtnLearnHow>
                          </PrivacyTag>
                        </Card>
                </CardContainer>
          </Container>
          
        </Grid>
        )
      }
    }


    const tagiOSLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'sc_link_ios'
      }
    }

    const tagAndroidLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
        tag: 'sc_link_android'
      }
    }

    const tagReactLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
        tag: 'sc_link_react'
      }
    }

    const tagAWSLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
        tag: 'sc_link_aws'
      }
    }


    
    

