import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
// import TrainYourEars from './TrainYourEars';
import ImgLogo from "./../../../res/images/HBLogo.svg";
import ImgWingnut from "./../../../res/images/Wingnut_Logo.svg";
import ImgBtnIcon from "./../../../res/images/ic_arrow_right_turq.svg";
import TagManager from 'react-gtm-module'
import { HBTurq } from '../../../config/theme';

const Grid = styled.div`
  /* border: 1px red dashed; */
  grid-area: 3 / 1 / 4 / 2;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 120px;
  background-color: black;
  ${media.lessThan("medium")`
  padding-left: 0px;
;  `}
  ${media.between("medium", "large")`
    padding-left: 0px;
  `}
  ${media.greaterThan("large")`
  `}
`;

const Container = styled.div`
  /* border: 1px yellow dashed; */
  width: 100%;
  max-width: 1440px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 35px;
  ${media.lessThan("medium")`
;  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;


const TitleContainer = styled.div`
  /* border: 1px blue dashed; */
  max-width: 1440px;
  display: flex;
  height: auto;
  flex-direction: column;
  padding-top: 80px;
  ${media.lessThan("medium")`
    justify-content: center;
    padding-top: 60px;
    width: 100%;
  `}
  ${media.between("medium", "large")`
    padding-top: 60px;
    width: 80%;
    align-self: center;
  `}
  ${media.greaterThan("large")`
  `}
`;

const KeywordText = styled.h2`
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  justify-self: center;
  font-size: 20px;
  color: #46FEFA;
  margin-bottom: 20px;
  ${media.lessThan("medium")`
    font-size: 15px;
`}
  ${media.between("medium", "large")`
    /* margin-left: 70px; */
`}
`;

const TitleText = styled.h1`
  max-width: 900px;
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: white;
  justify-self: center;
  font-size: 30px;
  line-height: 50px;
  ${media.lessThan("medium")`
  font-size: 20px;
`}
  ${media.between("medium", "large")`
`}
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Card = styled.div`
    /* border: 2px green dashed; */
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 1440px;
    margin-top: 80px;
    align-self: center;
    justify-content: space-between;
    ${media.lessThan("medium")`
    flex-direction: column;
    width: 100%;
    `}
  ${media.between("medium", "large")`
  flex-direction: column;
    `}
`;

const HBImg = styled.img`
    /* border: 2px green dashed; */
    width: 200px;
    ${media.lessThan("medium")`
    width: 100px;
    `}
    ${media.between("medium", "large")`
    width: 140px;
    `}
`;

const WingnutImg = styled.img`
    /* border: 2px green dashed; */
    width: 200px;
    ${media.lessThan("medium")`
    width: 80px;
    `}
    ${media.between("medium", "large")`
    width: 100px;
    `}
`;

const CardInfoContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: column;
    width: 40%;
    /* margin-left: 60px; */
    ${media.lessThan("medium")`
        width: 100%;
        margin-left: 0px;
    `}
  ${media.between("medium", "large")`
        width: 100%;
    `}
`;


const Icon = styled.img`
    width: 80px;
    height: 80px;
`;

const CardTitle = styled.h1`
    font-family: Montserrat;
    font-weight: 600;
    font-size: 22px;
    line-height: 68px;
    text-align: left;
    color: white;
    margin-top: 0px;
    ${media.lessThan("medium")`
    margin-top: 20px;
    `}
  ${media.between("medium", "large")`
      margin-top: 20px;
    `}
`;

const CardText = styled.p`
    font-family: Montserrat;
    font-weight: normal;
    font-size: 17px;
    line-height: 40px;
    text-align: left;
    color: white;
    margin-bottom: 40px;
    ${media.lessThan("medium")`
    font-size: 16px;
`}
`;

const CardDetailsContainer = styled.div`
    /* border: 1px red dashed; */
    /* height: 100px; */
    display: flex;
    flex-direction: row;
    ${media.lessThan("medium")`
    `}
  ${media.between("medium", "large")`
    `}
`;

const DetailTitle = styled.h2`
    /* border: 1px green dashed; */
    width: 150px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.08em;
    /* text-align: center; */
    color: #fff;
    /* margin-right: 30px; */
    `;

const DetailText = styled.p`
    /* border: 1px yellow dashed; */
    width: 300px;
    font-family: Montserrat;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.08em;
    /* line-height: 34px; */
    text-align: left;
    color: #fff;
`;

const PrivacyTag = styled.a`
  text-decoration: none;
  ${media.lessThan("medium")`
  `}
  
  ${media.between("medium", "large")`

`}
`;


const BtnLearnHow = styled.button`
  display: flex;
  flex-direction: row;
  /* border: 1px green dashed; */
  border: 2px #15B1B2 solid;
  box-shadow: 0px 0px 10px #15B1B2 ;
  width: 200px;
  height: 55px;
  border-radius: 9px;
  padding-top: 2px;
  background: transparent;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  color: #46FEFA;
  text-align: center;
  justify-content: center;
  margin-top: 40px;
  ${media.lessThan("medium")`
  margin-left: 0px;
  height: 50px;
`}

  ${media.between("medium", "large")`
  margin-left: 0px;
`}
`;

const ButtonText = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #6EFAFF;
  align-self: center;
`;

const ArrowIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 10px;
  align-self: center;
`;


export default class ExploreWork extends Component {

  componentDidMount(){
    // TagManager.initialize(tagPageVisit)
  }

  handleiOSLink = () => {
    TagManager.initialize(tagiOSLink)
  }
  handleAndroidLink = () => {
    TagManager.initialize(tagAndroidLink)
  }

  render() {
  
    return (
        <Grid>
            <Container>
                <TitleContainer>
                    <KeywordText id='portfolio'>EXPLORE OUR WORK</KeywordText>
                </TitleContainer>
                    <CardContainer>
                        <Card>
                          <HBImg src={ImgLogo} />
                            <CardInfoContainer>
                                <CardTitle>Hear Beyond - Auditory Brain Training</CardTitle>
                                <CardText>
                                Hear Beyond integrates proprietary and industry  auditory training strategies with mobile technologies to sharpen listening skills in order to promote the highest quality of hearing for as long as possible.
                                </CardText>
                                <CardDetailsContainer>
                                    <DetailTitle>PLATFORM</DetailTitle>
                                    <DetailText>
                                    Mobile iOS and Android 
                                    </DetailText>
                                </CardDetailsContainer>
                                <CardDetailsContainer>
                                    <DetailTitle>SERVICES</DetailTitle>
                                    <DetailText>
                                        Firebase, Charts, AlamoFire, Resolver, SQlite, MPAndroidChart, Retrofit, Kodein, Room, Proprietary Audio Alogrithms
                                    </DetailText>
                                </CardDetailsContainer>
                                <PrivacyTag href="https://hearbeyond.world/" target="_blank">
                                  <BtnLearnHow>
                                    <ButtonText>Visit Site</ButtonText>
                                      <ArrowIcon src={ImgBtnIcon} />
                                  </BtnLearnHow>
                                </PrivacyTag>
                            </CardInfoContainer>
                        </Card>
                        <Card>
                          <WingnutImg src={ImgWingnut} />
                            <CardInfoContainer>
                                <CardTitle>Wingnut IoT</CardTitle>
                                <CardText>
                                    Wingnut interfaces with industry standard sensing equipment to provide universal system connectivity.
                                </CardText>
                                <CardDetailsContainer>
                                    <DetailTitle>PLATFORM</DetailTitle>
                                    <DetailText>
                                        Web Application
                                    </DetailText>
                                </CardDetailsContainer>
                                <CardDetailsContainer>
                                    <DetailTitle>SERVICES</DetailTitle>
                                    <DetailText>
                                        Fullstack Development, Responsive Web Application, SSO Security, 
                                        Component Library, QA Automation, API Integrations
                                    </DetailText>
                                </CardDetailsContainer>
                                <PrivacyTag href="https://wingnutiot.ca/" target="_blank">
                                  <BtnLearnHow>
                                    <ButtonText>Visit Site</ButtonText>
                                      <ArrowIcon src={ImgBtnIcon} />
                                  </BtnLearnHow>
                                </PrivacyTag>
                            </CardInfoContainer>
                        </Card>
                </CardContainer>

          </Container>
        </Grid>
        )
      }
    }
    
    const tagPageVisit = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_page_visit'
      }
    }

    const tagiOSLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_link_ios'
      }
    }

    const tagAndroidLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
        tag: 'hearbeyond_link_android'
      }
    }


    
    

